<template>
	<div class="container-fluid">
		<div class="row" v-if="loggedIn">
			<form class="col-lg-6 col-md-8 col-sm-10 col-xs-12 mx-auto mb-5" @submit.prevent="createPost()">
				<legend>New Post</legend>
				<div class="mb-3">
					<input type="text" class="form-control" id="title" v-model.trim="title" placeholder="Title" required>
				</div>
				<div class="mb-3">
					<textarea class="form-control" rows="4" v-model.trim="content" minlength="10" required></textarea>
				</div>
				<div class="form-text text-danger" v-if="errorMessage">
					{{ errorMessage }}
				</div>
				<button type="submit" class="btn btn-primary">Create Post</button>
			</form>
		</div>
		<feed :posts="feed" ref="feed"></feed>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState: mapAccountState } = createNamespacedHelpers("account");
const { mapState: mapFeedState } = createNamespacedHelpers("feed");

export default {
	data() {
		return {
			title: null,
			content: null,
			errorMessage: null
		};
	},
	watch: {
		loggedIn() {
			this.$refs.feed.$forceUpdate();
		}
	},
	computed: {
		...mapFeedState(["feed"]),
		...mapAccountState({
			loggedIn: state => state.token !== null && state.synced
		})
	},
	methods: {
		async createPost() {
			const result = await this.$store.dispatch("feed/createPost", {
				title: this.title,
				content: this.content
			});

			if(result.error) {
				this.errorMessage = result.error;
			} else {
				this.title = "";
				this.content = "";
				this.errorMessage = null;
			}
		}
	}
};
</script>

Post
